/* Apply a background image to the entire HTML document */
body {
    background-image: url("https://raw.githubusercontent.com/KeertanWork/Formjs_Class_changes/main/FormJS-Library-Mimic-master/background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  /* Style for a container that holds your content */
  .container {
    max-width: 600px;
    border-radius: 20px;
    border: 2px solid black;
    padding: 30px;
    margin: 30px auto; /* Center the container horizontally */
    background-color: white;
  }
  
  /* Style for a section that contains a scrollable table */
  .tableSection {
    max-width: 100%;
    margin: auto auto;
  }
  
  .totalCount{
    color: white;
    font-size:20px;
    margin: 20px 20px;
  }
  table, th, td {
    border: 1px solid;
    width: 100%;
    border-radius: 1px;
    /* font-size:small */
  }
  th, td {
    border: 1px solid;
    padding: 2px; 
}
  th{
    background-color: white;
    color: black;
  }
  tr{
    background-color: white;
    color: black;
  }
  
  /* Style for a page title */
  .page-title {
    text-align: center; /* Center-align the content */
    max-width: 100%;
    color: white;
    margin-top: 20px
  }
  
  /* Style for input fields within forms */
  .inputStyle {
    padding: 10px; /* Reduced padding for input fields */
    margin: 10px 0; /* Adjusted vertical margin */
  }
  
  /* Style for tables */
  .table {
    font-size: 16px;
    width: 100%; /* Make the table span the entire container */
    border-collapse: collapse; /* Collapse table borders */
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  
  /* Style for the employee form */
  .employee-form {
    border: 2px solid black;
    padding: 20px; /* Add padding to the form */
    margin: 20px 0; /* Adjust vertical margin */
    border-radius: 5px; /* Rounded corners for the form */
  }
  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 18px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  